import { types } from 'mobx-state-tree'
import { observe } from 'mobx'

import laboratory from './models/laboratory'
import user from './models/user'
import storeWithFlags from './utils/storeWithFlags'

const store = types
	.model({
		laboratory: types.optional(laboratory, {} as any),
		loggedUserToken: types.maybeNull(types.string),
		loggedUser: types.optional(user, {} as any)
	})
	.preProcessSnapshot((snap: any) => {
		if (snap && snap.constructor.name === `Object` && !snap[`$id`]) {
			snap = { ...snap }
			if (localStorage.getItem(`loggedUserToken`)) {
				snap[`loggedUserToken`] = localStorage.getItem(`loggedUserToken`)
			}
			if (localStorage.getItem(`laboratory`)) {
				snap[`laboratory`] = JSON.parse(localStorage.getItem(`laboratory`)!)
			}
		}
		return snap
	})
	.actions(self => ({
		subscribeLoggedUser(cb = () => { }) {
			let destroy1 = observe(
				self,
				`loggedUserToken`,
				cb
			)
			let destroy2 = observe(
				self,
				`loggedUser`,
				cb
			)
			return () => {
				destroy1()
				destroy2()
			}
		},
		setLaboratory(laboratory: any) {
			self.laboratory = laboratory
		},
		setLoggedUserToken(token: any) {
			self.loggedUserToken = token
		},
		setLoggedUser(loggedUser: any) {
			self.loggedUser = loggedUser
		},
		hasAdminPermissions() {
			return self.loggedUser.asAdmin.permissions.length > 0
		}
	}))

export default types
	.compose(
		storeWithFlags,
		store
	)
	.create({})