import { types } from 'mobx-state-tree'

import _model from './_model'
import laboratory from './laboratory'
import sectorStage from './sectorStage'

const sector = types
	.model({
		name: types.optional(types.string, ``),
		stages: types.array(sectorStage),
		laboratory: types.optional(types.union(
			types.string,
			types.reference(laboratory),
			laboratory
		), ``)
	})
	.actions(self => ({
		setName(value: any) {
			self.name = value
		},
		setStages(value: any) {
			self.stages = value
		},
		setLaboratory(value: any) {
			self.laboratory = value
		},
	}))

export default types
	.compose(
		_model,
		sector
	)
	.named(`sector`)