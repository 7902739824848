import './styles.scss'
import '@fortawesome/fontawesome-free/css/all.css'
import 'antd/dist/antd.css'
import 'bootstrap/scss/bootstrap.scss'

import { observer } from 'mobx-react-lite'
import React from 'react'
import DocumentMeta from 'react-document-meta'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'

import { ErrorBoundary } from './errorBoundary'
import localStoreContext from './store.context'
import * as firebase from './utils/firebase'
import { doQuery } from './utils/gqlClient'


const Lab = React.lazy(() => import(`./view/pages/lab`))
const SharedLogin = React.lazy(() => import(`./view/pages/shared`))

firebase.init()

let App = observer<any>(() => {

	let localStore = React.useRef(React.useContext(localStoreContext)).current

	let checkPlatformSessionUID = React.useCallback(() => {
		doQuery(`reload: utils_generatePlatformSessionUID_public`)
			.then((data = {}) => {
				let { reload = false } = data
				if (reload) window.location.reload()
			})
	}, [])

	React.useEffect(() => {
		checkPlatformSessionUID()
		localStore.setStringFlag(`hostname`, window.location.hostname)
		if (process.env.NODE_ENV === `development`) localStore.setStringFlag(`hostname`, `analogico.domingosfacioli.com.br`)
	}, []) // eslint-disable-line

	return (
		<ErrorBoundary>
			{/* @ts-ignore */}
			<DocumentMeta
				title={localStore.laboratory?.displayName ?? ``}
				description={localStore.laboratory?.settings?.aboutUs?.onlyText ?? ``}
			>
				<React.Suspense fallback={null}>
					{/* @ts-ignore */}
					<BrowserRouter>
						{
							localStore.stringFlags.get(`hostname`) === `shared.labconnect.com.br`
								// @ts-ignore
								? <Route path={`/`} component={SharedLogin} />
								// @ts-ignore
								: <Route path={`/`} component={Lab} />
						}
					</BrowserRouter>
				</React.Suspense>
			</DocumentMeta>
		</ErrorBoundary>
	)
})

ReactDOM.render(
	// @ts-ignore
	<App />,
	document.getElementById(`root`)
)