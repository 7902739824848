import { types } from 'mobx-state-tree'

import _model from './_model'
import laboratory from './laboratory'
import agendaAvailability from './agendaAvailability'

const schema = types
	.model({
		laboratory: types.optional(types.union(
			laboratory,
			types.string
		), ``),
		name: types.optional(types.string, ``),
		availabilities: types.array(agendaAvailability)
	})
	.actions(self => ({
		setLaboratory(value: any) {
			self.laboratory = value
		},
		setName(value: any) {
			self.name = value
		},
		setAvailabilities(value: any) {
			self.availabilities = value
		}
	}))

export default types
	.compose(
		_model,
		schema
	)
	.named(`agenda`)