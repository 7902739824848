import { getSnapshot, types } from 'mobx-state-tree'

import generateUID from '../utils/generateUID'

const model = types
	.model({
		'$id': types.identifier,
		_id: types.maybe(types.string)
	})
	.preProcessSnapshot(snap => {
		if (snap && snap.constructor.name === `Object` && !snap[`$id`]) {
			snap = { ...snap }
			snap[`$id`] = generateUID()
		}
		return snap
	})
	.actions(self => ({
		set_Id(id: any) {
			self._id = id
		}
	}))
	.views(self => ({
		getSnapshot(node = self) {
			let removeBuiltInId = (obj: any) => {
				if (typeof obj === `object`) {
					if (Array.isArray(obj)) {
						obj = [...obj]
						obj = obj.map((k: any) => {
							if (typeof k === `object`) k = removeBuiltInId(k)
							return k
						})
					} else {
						obj = { ...obj }
						let keys = Object.keys(obj)
						if (keys.includes(`$id`)) delete obj[`$id`]
						keys.forEach(k => {
							if (typeof obj[k] === `object`) obj[k] = removeBuiltInId(obj[k])
						})
					}
				}
				return obj
			}
			return removeBuiltInId(getSnapshot(node))
		}
	}))

export default model
