import { types } from 'mobx-state-tree'

import _model from './_model'

const schema = types
	.model({
		address: types.maybe(types.string),
		number: types.maybe(types.string),
		complement: types.maybe(types.string),
		district: types.maybe(types.string),
		zipCode: types.maybe(types.string),
		city: types.maybe(types.string),
		state: types.maybe(types.string),
		country: types.maybe(types.string),
		latLon: types.frozen({})
	})
	.actions(self => ({
		setLatLon(value: any) {
			self.latLon = value
		},
		setNumber(value: any) {
			self.number = value
		},
		setAddress(address: any) {
			self.address = address
		},
		setComplement(complement: any) {
			self.complement = complement
		},
		setDistrict(district: any) {
			self.district = district
		},
		setZipCode(zipCode: any) {
			self.zipCode = zipCode
		},
		setCity(city: any) {
			self.city = city
		},
		setState(state: any) {
			self.state = state
		},
		setCountry(country: any) {
			self.country = country
		}
	}))

export default types
	.compose(
		_model,
		schema
	)
	.named(`address`)