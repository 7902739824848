import { types } from 'mobx-state-tree'

import _model from './_model'

const schema = types
	.model({
		group: types.optional(types.string, ``),
		name: types.optional(types.string, ``),
		uid: types.optional(types.string, ``)
	})
	.actions(self => ({
		setGroup(group: any) {
			self.group = group
		},
		setName(name: any) {
			self.name = name
		},
		setUID(value: any) {
			self.uid = value
		}
	}))

export default types
	.compose(
		_model,
		schema
	)
	.named(`adminPermission`)