import { types } from 'mobx-state-tree'

import storeWithFlags from '../utils/storeWithFlags'
import _model from './_model'
import address from './address'
import adminPermission from './adminPermission'
import agenda from './agenda'
import laboratory from './laboratory'
import sector from './sector'

const asCustomer = types
	.model({
		prePaidCredits: types.optional(types.number, 0),
		shippingFareMode: types.optional(types.enumeration([`ever`, `ask`, `never`, `eachXDays`]), `ever`),
		shippingFareEachXDays: types.optional(types.number, 0),
		shippingFareLastChargeDate: types.maybeNull(types.string),
		active: types.optional(types.boolean, true),
		cro: types.optional(types.union(
			types.string,
			types.null
		), ``),
		type: types.optional(types.enumeration([`person`, `business`]), `person`),
		birthdate: types.maybe(types.string),
		taxRegime: types.maybe(types.string),
		federalDocument: types.maybe(types.string),
		stateDocument: types.maybe(types.string),
		phone: types.optional(types.string, ``),
		lastOrderDate: types.maybe(types.string),
		address: types.optional(address, {} as any)
	})
	.actions(self => ({
		setTaxRegime(value: string) {
			self.taxRegime = value
		},
		setPrePaidCredits(value: number) {
			self.prePaidCredits = value
		},
		setLastOrderDate(value: string) {
			self.lastOrderDate = value
		},
		setActive(value: any) {
			self.active = Boolean(value)
		},
		setShippingFareMode(value: any) {
			self.shippingFareMode = value
		},
		setShippingFareEachXDays(value: number) {
			self.shippingFareEachXDays = value
		},
		setShippingFareLastChargeDate(value: any) {
			self.shippingFareLastChargeDate = value
		},
		setCRO(value: any) {
			self.cro = value
		},
		setType(type: any) {
			self.type = type
		},
		setBirthdate(birthdate: any) {
			self.birthdate = birthdate
		},
		setFederalDocument(federalDocument: any) {
			self.federalDocument = federalDocument
		},
		setStateDocument(stateDocument: any) {
			self.stateDocument = stateDocument
		},
		setPhone(phone: any) {
			self.phone = phone
		},
		setAddress(address: any) {
			self.address = address
		},
	}))

const asAdmin = types
	.model({
		sectors: types.array(types.union(
			types.string,
			sector
		)),
		agendas: types.array(types.union(
			types.string,
			agenda,
			types.reference(agenda),
		)),
		permissions: types.array(types.union(
			types.string,
			adminPermission
		)),
	})
	.actions(self => ({
		setSectors(value: any) {
			self.sectors = value
		},
		setAgendas(value: any) {
			self.agendas = value
		},
		setPermissions(value: any) {
			self.permissions = value
		}
	}))

const schema = types
	.model({
		laboratory: types.optional(types.union(
			types.string,
			laboratory
		), ``),
		active: types.maybe(types.boolean),
		email: types.maybe(types.string),
		password: types.maybe(types.string),
		name: types.maybe(types.string),
		asAdmin: types.optional(asAdmin, {}),
		asCustomer: types.optional(asCustomer, {}),
	})
	.actions(self => ({
		setLaboratory(value: any) {
			self.laboratory = value
		},
		setActive(active: any) {
			self.active = active
		},
		setEmail(email: any) {
			self.email = email
		},
		setPassword(password: any) {
			self.password = password
		},
		setName(name: any) {
			self.name = name
		}
	}))

export default types
	.compose(
		_model,
		schema,
		storeWithFlags
	)
	.named(`user`)