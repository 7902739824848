import { types } from 'mobx-state-tree'

import storeWithFlags from '../utils/storeWithFlags'
import _model from './_model'
import address from './address'

const color = types
	.model({
		main: types.maybe(types.string),
		lighter: types.maybe(types.string),
		darker: types.maybe(types.string),
		text: types.maybe(types.string)
	})
	.actions(self => ({
		setMain(main: any) {
			self.main = main
		},
		setLighter(lighter: any) {
			self.lighter = lighter
		},
		setDarker(darker: any) {
			self.darker = darker
		},
		setText(text: any) {
			self.text = text
		}
	}))

const colors = types
	.model({
		primary: types.optional(color, {}),
		secondary: types.optional(color, {})
	})

const aboutUs = types
	.model({
		onlyText: types.optional(types.string, ``),
		text: types.optional(types.string, ``),
		images: types.array(types.string)
	})
	.actions(self => ({
		setOnlyText(value: any) {
			self.onlyText = value
		},
		setText(value: any) {
			self.text = value
		},
		setImages(value: any) {
			self.images = value
		}
	}))

const settings = types
	.model({
		defaultTimestamp: types.optional(types.string, ``),
		aboutUs: types.optional(aboutUs, {}),
		paymentCreditCardDescription: types.optional(types.string, ``),
		defaultTimezone: types.optional(types.string, ``),
		chatButtonText: types.optional(types.string, ``),
		publicWhatsAppNumber: types.optional(types.string, ``),
		createReceivableBillForPostPaidOrder: types.optional(types.boolean, false),
		showServiceReadableIdInCatalog: types.optional(types.boolean, false),
		orderMirrorPrintServices: types.optional(types.boolean, false),
		showPublicStatistics: types.optional(types.boolean, false),
		availablePaymentMethods: types.optional(types.array(types.string), []),
		availablePaymentMethodsToBuyPrePaidCredits: types.optional(types.array(types.string), []),
		internalAvailablePaymentMethods: types.optional(types.array(types.string), []),
		invoiceRequestPayerFederalDocument: types.optional(types.boolean, false),
		percentageDiscountWhenPrePaid: types.optional(types.number, 0),
		newCustomersPostPaidStatus: types.optional(types.boolean, false),
		newCustomersStatus: types.optional(types.boolean, false),
		newCustomersShippingFareMode: types.optional(types.enumeration([`ever`, `ask`, `never`, `eachXDays`]), `ever`),
		newCustomersShippingFareEachXDays: types.optional(types.number, 0),
		workingDays: types.optional(types.frozen({} as any), {} as any),
		invoiceDefaultTermInCommercialDays: types.optional(types.number, 0),
		pixAbsorbFee: types.optional(types.boolean, false),
		pixFee: types.optional(types.number, 0),
		bankSlipFee: types.optional(types.number, 0),
		creditCardFees: types.optional(types.frozen({} as any), {} as any),
		mensalistCreditCardPolicies: types.optional(types.frozen({}), {}),
		fintechCreditCardPolicies: types.optional(types.frozen({}), {}),
		priceVisibility: types.maybe(types.enumeration([`public`, `nonPublic`])),
	})
	.actions(self => ({
		setOrderMirrorPrintServices(value: any) {
			self.orderMirrorPrintServices = value
		},
		setInvoiceDefaultTermInCommercialDays(value: any) {
			self.invoiceDefaultTermInCommercialDays = value
		},
		setAvailablePaymentMethodsToBuyPrePaidCredits(value: any) {
			self.availablePaymentMethodsToBuyPrePaidCredits = value
		},
		setAvailablePaymentMethods(value: any) {
			self.availablePaymentMethods = value
		},
		setPublicWhatsAppNumber(value: any) {
			self.publicWhatsAppNumber = value
		},
		setShowServiceReadableIdInCatalog(value: any) {
			self.showServiceReadableIdInCatalog = value
		},
		setShowPublicStatistics(value: any) {
			self.showPublicStatistics = value
		},
		setPixAbsorbFee(value: any) {
			self.pixAbsorbFee = value
		},
		setFintechCreditCardPolicies(value: any) {
			self.fintechCreditCardPolicies = value
		},
		setMensalistCreditCardPolicies(value: any) {
			self.mensalistCreditCardPolicies = value
		},
		setChatButtonText(value: any) {
			self.chatButtonText = value
		},
		setInvoiceRequestPayerFederalDocument(value: any) {
			self.invoiceRequestPayerFederalDocument = value
		},
		setPercentageDiscountWhenPrePaid(value: any) {
			self.percentageDiscountWhenPrePaid = value
		},
		setNewCustomersPostPaidStatus(value: any) {
			self.newCustomersPostPaidStatus = value
		},
		setNewCustomersStatus(value: any) {
			self.newCustomersStatus = value
		},
		setNewCustomersShippingFareMode(value: any) {
			self.newCustomersShippingFareMode = value
		},
		setNewCustomersShippingFareEachXDays(value: number) {
			self.newCustomersShippingFareEachXDays = value
		},
		setDefaultTimezone(value: any) {
			self.defaultTimezone = value
		},
		setPaymentCreditCardDescription(value: any) {
			self.paymentCreditCardDescription = value
		},
		setWorkingDays(value: any) {
			self.workingDays = value
		},
		setPriceVisibility(value: any) {
			self.priceVisibility = value
		},
	}))

const schema = types
	.model({
		displayName: types.maybe(types.string),
		socialName: types.maybe(types.string),
		federalDocument: types.maybe(types.string),
		stateDocument: types.maybe(types.string),
		hostnames: types.array(types.string),
		logo: types.maybe(types.string),
		emails: types.array(types.string),
		address: types.optional(address, {} as any),
		phones: types.array(types.string),
		active: types.maybe(types.boolean),
		colors: types.optional(colors, {} as any),
		settings: types.optional(settings, {} as any),
	})
	.actions(self => ({
		setHostnames(value: any) {
			self.hostnames = value
		},
		setDisplayName(displayName: any) {
			self.displayName = displayName
		},
		setSocialName(socialName: any) {
			self.socialName = socialName
		},
		setFederalDocument(federalDocument: any) {
			self.federalDocument = federalDocument
		},
		setStateDocument(stateDocument: any) {
			self.stateDocument = stateDocument
		},
		setLogo(logo: any) {
			self.logo = logo
		},
		setEmails(emails: any) {
			self.emails = emails
		},
		setAddress(address: any) {
			self.address = address
		},
		setPhones(phones: any) {
			self.phones = phones
		},
		setActive(active: any) {
			self.active = active
		}
	}))

export default types
	.compose(
		_model,
		schema,
		storeWithFlags
	)
	.named(`laboratory`)