import { types } from 'mobx-state-tree'

import _model from './_model'

const schema = types
	.model({
		agenda: types.optional(types.string, ``),
		date: types.optional(types.string, ``),
		from: types.optional(types.string, ``),
		to: types.optional(types.string, ``),
	})
	.actions(self => ({
		setAgenda(value: any) {
			self.agenda = value
		},
		setDate(value: any) {
			self.date = value
		},
		setFrom(value: any) {
			self.from = value
		},
		setTo(value: any) {
			self.to = value
		},
	}))

export default types
	.compose(
		_model,
		schema
	)
	.named(`agendaAvailability`)