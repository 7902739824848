import { InMemoryCache } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import gql from 'graphql-tag'
import moment from 'moment'
import parseDomain from 'parse-domain'

import rootStore from '../store'

// import ee from './ee'
const parsedDomain: any = parseDomain(window.location.href) || {}

const formatDataToSend = (data: any) => Buffer.from(JSON.stringify(data)).toString(`base64`)

let gqlHTTPURL = `https://api-labconnect.${parsedDomain.domain}.${parsedDomain.tld}/graphql`
gqlHTTPURL = process.env.NODE_ENV === `development` ? `http://${window.location.hostname}:9090/graphql` : gqlHTTPURL

let downloadFileURL = `https://api-labconnect.${parsedDomain.domain}.${parsedDomain.tld}/downloadFile`
downloadFileURL = process.env.NODE_ENV === `development` ? `http://${window.location.hostname}:9090/downloadFile` : downloadFileURL

let middlewareHTTPLink = new ApolloLink((operation, forward) => {
	const headers: any = {
		clientURL: window.location.href,
		clientTimestamp: moment().format(),
		loggedUserToken: localStorage.getItem(`loggedUserToken`),
		pushConfig: localStorage.getItem(`pushConfig`),
		appVersion: localStorage.getItem(`appVersion`) || 0,
		from: localStorage.getItem(`appPlatform`) || `web`
	}
	if (rootStore.laboratory._id) headers[`currentLaboratory`] = rootStore.laboratory._id
	operation.setContext({ headers })
	return forward(operation)
})

let apolloClientHTTP = new ApolloClient({
	link: middlewareHTTPLink.concat(
		createHttpLink({
			uri: gqlHTTPURL,
			credentials: `include`
		})
	),
	cache: new InMemoryCache({ addTypename: false })
})

const getDownloadFileURL = () => {
	return downloadFileURL
}

const getGQLHTTPURL = () => {
	return gqlHTTPURL
}

const errorMiddleware = (resp: any) => {
	// TODO - tmp (?)
	if (`${resp}`.includes(`ensureSecure_invalidSession`)) window.location.reload()
	// if(resp.toString().match(/ensureSecure/)) ee.emit(`labDoLogout`)
	return resp
}

const doQuery = async (query = ``) => {
	query = query.replace(/\t/gi, ``).replace(/\n/gi, ``)
	return await apolloClientHTTP
		.query({
			query: gql`query{ ${query} }`,
			fetchPolicy: `no-cache`
		})
		.then(data => data.data).catch(e => {
			errorMiddleware(e)
			return { error: e.toString() }
		})
}

const doMutation = async (query = ``) => {
	query = query.replace(/\t/gi, ``).replace(/\n/gi, ``)
	return await apolloClientHTTP
		.mutate({
			mutation: gql`mutation{ ${query} }`,
			fetchPolicy: `no-cache`
		})
		.then(data => data.data).catch(e => {
			errorMiddleware(e)
			return { error: e.toString() }
		})
}

const doUpload = (query = ``, file: any, fileName: string, callback: (...args: any[]) => any) => {
	query = query.replace(/\t/gi, ``).replace(/\n/gi, ``)
	let formData = new FormData()
	formData.append(`operations`, JSON.stringify({
		query: `mutation($file: Upload!){ ${query} }`
	}))
	formData.append(`map`, JSON.stringify({
		0: [`variables.file`]
	}))
	formData.append(`0`, file, fileName)
	let xhr = new XMLHttpRequest()
	xhr.open(`POST`, gqlHTTPURL)
	xhr.withCredentials = true
	xhr.setRequestHeader(`loggedUserToken`, localStorage.getItem(`loggedUserToken`)!)
	if (rootStore.laboratory) xhr.setRequestHeader(`currentLaboratory`, rootStore.laboratory._id!)
	xhr.setRequestHeader(`appVersion`, localStorage.getItem(`appVersion`) || `0`)
	xhr.setRequestHeader(`from`, localStorage.getItem(`appPlatform`) || `web`)
	xhr.upload.onprogress = (e) => {
		if (e.lengthComputable) {
			callback({
				type: `progress`,
				data: {
					progress: (e.loaded / e.total) * 100
				}
			})
		} else {
			callback({
				type: `progress`,
				data: {
					progress: 100
				}
			})
		}
	}
	xhr.onload = () => {
		let data = JSON.parse(xhr.responseText)
		if (
			data.errors
			&& data.errors[0]
		) {
			callback({
				type: `done`,
				error: data.errors[0].message,
				...data
			})
			return
		}
		callback({
			type: `done`,
			...data
		})
	}
	xhr.send(formData)
}

const encodeObject = (obj: any, child = false) => {
	let toReturn: any
	if (typeof obj === `object`) {
		if (Array.isArray(obj)) {
			toReturn = obj.map(k => {
				if (
					typeof k === `object`
					&& k !== null
				) {
					k = encodeObject(k, true)
				}
				return k
			})
		} else {
			toReturn = toReturn || {}
			let keys = Object.keys(obj)
			keys.forEach(k => {
				let newK = k
				newK = newK.replace(/([0-9]+)/gi, `escaped_$1`)
				newK = newK.replace(/ /gi, `_space_`)
				if (
					typeof obj[k] === `object`
					&& obj[k] !== null
				) {
					toReturn[newK] = encodeObject(obj[k], true)
				} else {
					toReturn[newK] = obj[k]
				}
			})
		}
		if (!child) {
			toReturn = JSON.stringify(toReturn)
			toReturn = toReturn.replace(/"([a-zA-Z0-9._-]+)":/gi, `$1:`)
		}
	}
	return toReturn
}

export {
	gql,
	apolloClientHTTP,
	getDownloadFileURL,
	getGQLHTTPURL,
	doQuery,
	doMutation,
	doUpload,
	encodeObject,
	formatDataToSend
}