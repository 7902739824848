import { types } from 'mobx-state-tree'

const store = types
	.model({
		booleanFlags: types.map(types.boolean),
		numberFlags: types.map(types.number),
		stringFlags: types.map(types.string),
		objFlags: types.map(types.frozen()),
		arrayFlags: types.map(types.array(types.frozen()))
	})
	.preProcessSnapshot((snap: any) => {
		let _snap = { ...snap }
		if (_snap) {
			if (((_snap.booleanFlags || {}).constructor || {}).name === `Map`) _snap.booleanFlags = Object.fromEntries(_snap.booleanFlags)
			if (((_snap.stringFlags || {}).constructor || {}).name === `Map`) _snap.stringFlags = Object.fromEntries(_snap.stringFlags)
			if (((_snap.numberFlags || {}).constructor || {}).name === `Map`) _snap.numberFlags = Object.fromEntries(_snap.numberFlags)
			if (((_snap.objFlags || {}).constructor || {}).name === `Map`) _snap.objFlags = Object.fromEntries(_snap.objFlags)
			if (((_snap.arrayFlags || {}).constructor || {}).name === `Map`) _snap.arrayFlags = Object.fromEntries(_snap.arrayFlags)

			if (typeof _snap.booleanFlags === `object`) {
				_snap.booleanFlags = { ..._snap.booleanFlags }
				Object.keys(_snap.booleanFlags).forEach(k => {
					if (typeof _snap.booleanFlags[k] !== `boolean`) delete _snap.booleanFlags[k]
				})
			}
			if (typeof _snap.stringFlags === `object`) {
				_snap.stringFlags = { ..._snap.stringFlags }
				Object.keys(_snap.stringFlags).forEach(k => {
					if (typeof _snap.stringFlags[k] !== `string`) delete _snap.stringFlags[k]
				})
			}
			if (typeof _snap.numberFlags === `object`) {
				_snap.numberFlags = { ..._snap.numberFlags }
				Object.keys(_snap.numberFlags).forEach(k => {
					if (typeof _snap.numberFlags[k] !== `number`) delete _snap.numberFlags[k]
				})
			}
		}
		return _snap
	})
	.actions(self => ({
		deleteArrayFlag(flag: any) {
			self.arrayFlags.delete(flag)
			Array.from(self.arrayFlags.keys()).forEach(key => {
				if (key && key.match(new RegExp(flag))) self.arrayFlags.delete(key)
			})
		},
		setArrayFlag(flag: any, value: any) {
			self.arrayFlags.set(flag, value)
		},
		deleteObjFlag(flag: any) {
			self.objFlags.delete(flag)
			Array.from(self.objFlags.keys()).forEach(key => {
				if (key && key.match(new RegExp(flag))) self.objFlags.delete(key)
			})
		},
		setObjFlag(flag: any, value: any) {
			self.objFlags.set(flag, value)
		},
		setBooleanFlag(flag: any, value: any) {
			self.booleanFlags.set(flag, !!value)
		},
		deleteBooleanFlag(flag: any) {
			self.booleanFlags.delete(flag)
			Array.from(self.booleanFlags.keys()).forEach(key => {
				if (key && key.match(new RegExp(flag))) self.booleanFlags.delete(key)
			})
		},
		setNumberFlag(flag: any, direction: any) {
			if (typeof direction === `string`) {
				switch (direction) {
					case `+`:
						self.numberFlags.set(flag, (self.numberFlags.get(flag) || 0) + 1)
						break
					case `-`:
						self.numberFlags.set(flag, (self.numberFlags.get(flag) || 1) - 1)
						break
				}
			}
			if (typeof direction === `number`) {
				self.numberFlags.set(flag, direction)
			}
		},
		deleteNumberFlag(flag: any) {
			self.numberFlags.delete(flag)
			Array.from(self.numberFlags.keys()).forEach(key => {
				if (key && key.match(new RegExp(flag))) self.numberFlags.delete(key)
			})
		},
		setStringFlag(flag: any, value: any) {
			self.stringFlags.set(flag, value)
		},
		deleteStringFlag(flag: any) {
			self.stringFlags.delete(flag)
			Array.from(self.stringFlags.keys()).forEach(key => {
				if (key && key.match(new RegExp(flag))) self.stringFlags.delete(key)
			})
		}
	}))
	.views(self => ({
		getArrayFlag(flag: any) {
			if (self.arrayFlags && self.arrayFlags.get) {
				return self.arrayFlags.get(flag) || []
			} else {
				return []
			}
		},
		getObjFlag(flag: any) {
			if (self.objFlags && self.objFlags.get) {
				return self.objFlags.get(flag) || {}
			} else {
				return {}
			}
		},
		getStringFlag(flag: any) {
			if (self.stringFlags && self.stringFlags.get) {
				return self.stringFlags.get(flag) || ``
			} else {
				return ``
			}
		},
		getBooleanFlag(flag: any) {
			if (self.booleanFlags && self.booleanFlags.get) {
				return self.booleanFlags.get(flag) || false
			} else {
				return false
			}
		},
		getNumberFlag(flag: any) {
			if (self.numberFlags && self.numberFlags.get) {
				return self.numberFlags.get(flag) || 0
			} else {
				return 0
			}
		}
	}))

export default store