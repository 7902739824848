import firebase from 'firebase/app'
import 'firebase/messaging'

export const init = async () => {
	firebase.initializeApp({
		apiKey: "AIzaSyAILmRTJHHFObaG6IviTuzwef2fssxryqw",
		authDomain: "labconnect-783d8.firebaseapp.com",
		databaseURL: "https://labconnect-783d8.firebaseio.com",
		projectId: "labconnect-783d8",
		storageBucket: "labconnect-783d8.appspot.com",
		messagingSenderId: "145475817339",
		appId: "1:145475817339:web:c36865b8e32c22941e77de"
	})

	if (global.localStorage.getItem(`appPlatform`)) return

	try {
		const messaging = firebase.messaging()
		const token = await messaging.getToken()
		if (!global.localStorage.getItem(`appPlatform`)) global.localStorage.setItem(`pushConfig`, token)
		messaging.onMessage(payload => {
			navigator.serviceWorker
				.getRegistration('/firebase-cloud-messaging-push-scope')
				.then((registration: any) => {
					if (registration && registration.showNotification) {
						const notificationTitle = payload.notification.title
						const notificationOptions = {
							body: payload.notification.body,
							icon: payload.notification.icon
						} as any
						if ((payload.notification.click_action || ``).trim().length > 0) notificationOptions.data = notificationOptions.click_action = payload.notification.click_action
						registration.showNotification(notificationTitle, notificationOptions)
					}
				})
		})
	} catch (e) { } // eslint-disable-line no-empty
}