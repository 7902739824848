import React from 'react'
import Stacktracey from 'stacktracey'

import { doMutation } from './utils/gqlClient'

export class ErrorBoundary extends React.Component {
	// state = { hasError: false }

	// static getDerivedStateFromError() {
	// 	return { hasError: true }
	// }

	componentDidCatch(error: any) {
		if (process.env.NODE_ENV === `production`) {
			doMutation(`
				apiError(
					error: "${Buffer.from(JSON.stringify({ error: `${error}`, stack: new Stacktracey(error).asTable() })).toString(`base64`)}",
					extraArgs: "${Buffer.from(JSON.stringify({ from: `REACT`, url: window.location.href })).toString(`base64`)}}"
				)
			`).finally(() => {
				if (caches) caches.keys().then(function (names) { for (let name of names) caches.delete(name) })
				// window.location.href = window.location.href
			})
		}
	}

	render() {
		// if (this.state.hasError) {
		// 	return (
		// 		<div style={{ display: `flex`, flexDirection: `column`, width: `100vw`, height: `100vh`, justifyContent: `center`, alignItems: `center` }}>
		// 			<h1>{`Oopss... Algo deu errado!`}</h1>
		// 			{`Mas fique tranquilo, já estamos sabendo e logo tudo voltará ao normal...`}
		// 		</div>
		// 	)
		// }
		return <>{this.props.children}</> // eslint-disable-line
	}
}