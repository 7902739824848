import { types } from 'mobx-state-tree'

import _model from './_model'

const sectorStage = types
	.model({
		sector: types.frozen({}),
		name: types.optional(types.string, ``),
		asStart: types.optional(types.boolean, false),
		position: types.optional(types.number, 0),
		type: types.optional(types.enumeration([
			`normal`,
			`workStart`,
			`workEnd`,
		]), `normal`),
		asEnd: types.optional(types.boolean, false),
		canGoTo: types.array(types.string)
	})
	.actions(self => ({
		setSector(value: any) {
			self.sector = value
		},
		setPosition(value: any) {
			self.position = value
		},
		setName(value: any) {
			self.name = value
		},
		setType(value: any) {
			self.type = value
		},
		setCanGoTo(value: any) {
			self.canGoTo = value
		}
	}))

export default types
	.compose(
		_model,
		sectorStage
	)
	.named(`sectorStage`)